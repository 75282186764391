import React from "react";
import classnames from "classnames";

const Card = ({ children, title, noPadding, className, ...rest }) => {
  return (
    <div
      className={classnames(
        "bg-white overflow-hidden flex flex-col justify-between rounded-md shadow-md dark:bg-gray-600 transition-colors",
        className
      )}
      {...rest}
    >
      {title && <div className="text-lg px-6 py-1 bg-gradient-to-r text-white from-purple-500 to-red-500">{title}</div>}
      <div className={classnames(!noPadding && "p-6", "flex-grow")}>{children}</div>
    </div>
  );
};

export default Card;
