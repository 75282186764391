import React, { useMemo } from "react";
import Prism from "prismjs";
import classnames from "classnames";

export default ({ children, type, className, noParse = false, ...rest }) => {
  const html = useMemo(() => (noParse ? children : Prism.highlight(children, Prism.languages[type], type)), [
    children,
    type,
    noParse
  ]);
  return (
    <pre className={classnames(`language-${type}`, className)} {...rest}>
      <code className={`language-${type}`} dangerouslySetInnerHTML={{ __html: html }}></code>
    </pre>
  );
};
